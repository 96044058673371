<!-- @format -->

<template>
  <div>
    <div class="bg-white border px-6 py-4 rounded-t-lg">Added Activities</div>
    <div v-if="props.loader" class="bg-white py-4">
      <TableSkeletonLoader :loader="props.loader" :cols="5" :rows="4" />
    </div>
    <table v-else class="border-b border-r border-l">
      <thead class="text-sm text-white bg-teal border-b">
        <tr>
          <th
            scope="col"
            class="px-6 py-4 tracking-wider text-left"
            :class="index === 0 ? 'w-1/2' : 'w-1/6'"
            v-for="(header, index) in props?.tableHeadings"
            :key="index"
          >
            {{ header }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(question, index) in props?.questions?.data"
          :key="question"
          class="bg-white border-b text-sm text-gray-600 cursor-pointer"
          @click="emitClickOnAcitivity(question.id)"
        >
          <td>
            <div class="flex items-center px-6 py-4 capitalize truncate">
              {{ question.activity }}
            </div>
          </td>
          <td>
            <div class="flex items-center px-6 py-4 capitalize">
              {{ question.type }}
            </div>
          </td>
          <td>
            <div class="flex items-center px-6 py-4 capitalize">
              {{
                questions && question?.region?.length == 2
                  ? question?.region?.join(", ")
                  : question?.region
              }}
            </div>
          </td>
          <td>
            <div class="flex items-center px-6 py-4">
              {{
                question.shift == 1
                  ? "Morning"
                  : question.shift == 2
                  ? "Mid Day"
                  : "Evening"
              }}
            </div>
          </td>
          <td>
            <div
              class="flex items-center justify-center pl-2 mr-8 text-red cursor-pointer"
            >
              <div v-if="question.deleted_at" class="">Deleted</div>
              <div
                v-else
                class="material-icons w-6 border border-red rounded text-base pl-1 hover:bg-red hover:text-white"
                @click.stop="deleteQuestion(question?.id, index)"
              >
                delete_outlined
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="bg-white">
      <Pagination
        class="border rounded-b-lg"
        :currentPage="props?.questions?.current_page"
        :totalPages="props?.questions?.last_page"
        @page-changed="atPageChange"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, toRefs } from "vue";
import Pagination from "@/components/newUi/atoms/Pagination.vue";
import { useStore } from "vuex";
import icon from "@/assets/icons/error.svg";
import SuccessIcon from "@/assets/icons/success.svg";
import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";
import Swal from "sweetalert2";

const emit = defineEmits(["at-page-changed", "click-at-activity"]);
const store = useStore();
const props = defineProps({
  tableHeadings: {
    type: Array,
    required: true,
  },
  questions: {
    type: Array,
    required: true,
  },
  loader: {
    type: Boolean,
    required: true,
  },
});

const { questions } = toRefs(props);

const deleteQuestion = (question, index) => {
  Swal.fire({
    width: "35%",
    iconHtml: `<img src="${icon}" />`,
    title: `Are you sure you want to delete? `,
    showCancelButton: true,
    cancelButtonText: "Cancel",
    confirmButtonText: "Delete",
    reverseButtons: true,
    confirmButtonColor: "rgba(0, 164, 153, 1)",
    customClass: {
      title: "custom-delete-title",
      popup: "border-radius",
      content: "custom-text",
      icon: "icon-border",
      cancelButton: "custom-cancel-button",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      store
        .dispatch("hqModelDay/deleteActivity", {
          activityId: question,
        })
        .then(() => {
          Swal.fire({
            toast: true,
            position: "top-end",
            title: `Question Has Been Deleted Successfully`,
            iconHtml: `<img src="${SuccessIcon}" />`,
            width: "32em",
            customClass: {
              title: "popup-title",
              popup: "popup-border",
              content: "popup-text",
              icon: "popup-icon-border",
            },
            timer: 2000,
            showConfirmButton: false,
            background: `rgba(225, 249, 240, 1)`,
          });
          questions.value.data[index].deleted_at = true;
          Swal.fire({
            padding: "6px",
            title: "Activities List Will be Updated Tomorrow!",
            confirmButtonColor: "rgba(0, 164, 153, 1)",
            customClass: {
              title: "custom-delete-title",
              popup: "border-radius",
              content: "custom-text",
              icon: "icon-border",
              cancelButton: "custom-cancel-button",
            },
          });
        });
    }
  });
};

const atPageChange = (page) => {
  if (page > props?.questions?.last_page) {
    return;
  } else {
    emit("at-page-changed", page);
  }
};

const emitClickOnAcitivity = (activityId) => {
  emit("click-at-activity", activityId);
};
</script>
