<!-- @format -->

<template>
  <div>
    <div class="font-medium text-xl mb-4">
      {{ isNew ? "Add Activity" : "Edit Shift" }}
    </div>
    <div class="mb-8 flex items-center justify-between" v-if="!isNew">
      <label class="block mb-2">Activity</label>
      <button
        class="border border-teal text-teal px-4 py-1 rounded-md hover:bg-teal hover:text-white"
        @click="handleAddNewTask"
      >
        +Add Activity
      </button>
    </div>
    <Transition :duration="{ enter: 300, leave: 200 }">
      <div
        class="bg-white border border-gray-200 px-2 py-10 rounded-xl mb-4"
        v-if="addTask"
        :key="isView"
      >
        <div
          v-if="isView && loadingForm"
          class="bg-white flex items-center justify-center h-screen"
        >
          <svg
            class="text-gray-100 animate-spin"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
          >
            <path
              d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
              stroke="currentColor"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
              stroke="currentColor"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="text-teal"
            ></path>
          </svg>
        </div>
        <div v-else>
          <div
            class="bg-white px-6 rounded-xl"
            v-for="(form, index) in addQuestionForm"
            :key="index"
          >
            <div class="mt-8" v-if="index >= 1">
              <div class="border-teal border-t-2 border-dashed"></div>
            </div>
            <div class="text-teal text-sm font-medium pt-3 mb-4">
              Activity: {{ index + 1 }}
            </div>
            <div class="mb-4">
              <MultiSelect
                :label="'Region'"
                :modelValue="form.regions"
                :options="regions"
                placeholder="Multi Select regions"
                @update-modelValue="(event) => updateRegionValue(event, index)"
              />
            </div>
            <div class="mb-4">
              <MultiSelect
                :label="'Shift'"
                :options="shifts"
                :modelValue="form.shifts"
                placeholder="Choose Shift"
                @update-modelValue="(event) => updateShiftValue(event, index)"
              />
            </div>
            <div class="mb-4">
              <label class="text-gray-500 text-sm font-light">Question</label>
              <input
                v-model="form.question"
                type="text"
                :maxLength="100"
                :disabled="isView"
                placeholder="Type your question"
                class="w-full py-3 px-4 border-2 border-gray-200 rounded mt-2"
              />
              <small
                class="flex items-center text-gray-500"
                v-if="form.question?.length == 100"
              >
                <i class="material-icons w-5 pb-0.5 text-base">info_outlined</i>
                Max: 100 Characters</small
              >
            </div>
            <div class="mb-4">
              <label class="text-gray-500 text-sm font-light"
                >Question Type</label
              >
              <v-select
                label="name"
                v-model="form.questionType"
                :options="questionTypes"
                placeholder="Select Question Type"
                class="mt-2"
                :clearable="false"
                :disabled="
                  form.shifts.length === 0 ||
                  form.regions.length === 0 ||
                  isView
                "
              />
            </div>
            <Transition :duration="{ enter: 500, leave: 300 }">
              <div v-if="form.questionType.id == 3" class="mb-4">
                <ModelDayEditViewThresholdTable
                  :tableHeadings="tableHeadings[index]"
                  :tableBody="isView ? activity?.thresholds : practicesList"
                  :key="tableHeadings[index]"
                  :isView="isView"
                  :isLoading="loadingThresholdTable"
                  @at-threshold-input="(event) => updateThreshold(event, index)"
                />
              </div>
            </Transition>
          </div>
          <div class="mx-6" :key="formValid">
            <button
              v-if="!isView"
              @click="addNewQuestion"
              class="w-full p-2 text-sm text-center text-teal border-2 border-teal border-dashed rounded mb-4"
              :disabled="!isLastFormValid && loadingButton"
              :class="{
                'opacity-50 cursor-not-allowed': !isLastFormValid,
                'cursor-wait opacity-50': loadingButton,
              }"
            >
              + Add New Question
            </button>

            <div class="flex justify-between">
              <button
                class="px-8 py-2 border border-teal text-teal rounded"
                @click="handleCancelButton()"
                :disabled="isNew && addQuestionForm.length == 1"
                :class="{
                  'opacity-50 cursor-not-allowed':
                    isNew && addQuestionForm.length == 1,
                }"
              >
                {{ isView ? "Close" : "Cancel" }}
              </button>
              <button
                v-if="!isView"
                class="px-10 py-2 bg-teal text-base text-white rounded"
                :disabled="!areAllFormsValid && loadingButton"
                :class="{
                  'opacity-50 cursor-not-allowed': !areAllFormsValid,
                  'cursor-wait opacity-50': loadingButton,
                }"
                @click="addQuestions"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition>
      <div v-if="!isNew">
        <ModelDayQuestionsTable
          :tableHeadings="tableHeadingQuestionTable"
          :questions="shiftActivities"
          :loader="loader"
          @click-at-activity="fetchSingleActivity"
          @at-page-changed="pageChange"
        />
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import icon from "@/assets/icons/success.svg";
import MultiSelect from "@/components/newUi/atoms/MultiSelect.vue";
import ModelDayEditViewThresholdTable from "@/components/newUi/tables/ModelDayEditViewThresholdTable.vue";
import ModelDayQuestionsTable from "@/components/newUi/tables/ModelDayQuestionsTable.vue";

const route = useRoute();
const store = useStore();
const router = useRouter();
const activeShift = ref(parseInt(route.params.shiftId) + 1);
const activeRegion = ref(parseInt(route.params.regionId));
const isNew = ref(parseInt(route.params.isNew));
const addTask = ref(isNew.value);
const isView = ref(false);
const loader = ref(false);
const loadingForm = ref(false);
const loadingButton = ref(false);
const loadingThresholdTable = ref(true);
const page = ref(0);
const addQuestionForm = ref([
  {
    question: "",
    questionType: "",
    regions: [],
    shifts: [],
    practices: [],
  },
]);

const regions = [
  { id: 1, name: "England" },
  { id: 2, name: "Wales" },
];

const shifts = [
  { id: 1, name: "Morning 8:00 am" },
  { id: 2, name: "Mid Day 11:00 am" },
  { id: 3, name: "Evening 4:00 pm" },
];

const questionTypes = [
  { id: 1, name: "Date" },
  { id: 2, name: "String" },
  { id: 3, name: "Numeric" },
];

const tableHeadings = ref([[]]);

const tableHeadingQuestionTable = ref(["Question ", "Type", "Region", "Shift"]);

const updateShiftValue = (event, index) => {
  addQuestionForm.value[index].shifts = event;
  updateTableHeadings(index, event);
  if (addQuestionForm.value[index]?.shifts?.length == 0) {
    addQuestionForm.value[index].questionType = "";
  }
};

const formValid = ref(false);

const updateRegionValue = (event, index) => {
  addQuestionForm.value[index].regions = event;
  if (addQuestionForm.value[index]?.regions?.length == 0) {
    addQuestionForm.value[index].questionType = "";
  }
};

const updateTableHeadings = (index, selectedShifts) => {
  tableHeadings.value[index] = [
    "Practice List",
    ...selectedShifts.sort((a, b) => a.id - b.id).map((shift) => shift.name),
  ];
};

const updateThreshold = (event, index) => {
  if (
    addQuestionForm.value[index].practices[event.practiceIndex]
      .practiceThresholds[event.shiftId]
  ) {
    addQuestionForm.value[index].practices[
      event.practiceIndex
    ].practiceThresholds[event.shiftId] = {
      shiftId: event.shiftId + 1,
      threshold: event.threshold,
    };
  } else {
    addQuestionForm.value[index].practices[
      event.practiceIndex
    ].practiceThresholds.push({
      shiftId: event.shiftId + 1,
      threshold: event.threshold,
    });
  }
};

const showError = ref(false);

const isFormValid = (form) => {
  if (form.questionType.id == 3) {
    return (
      form.question.trim() !== "" &&
      form.questionType !== "" &&
      form.regions.length > 0 &&
      form.shifts.length > 0 &&
      form.practices.every(
        (practice) =>
          practice.practiceThresholds.length == form.shifts.length &&
          practice.practiceThresholds.every(
            (threshold) => threshold.threshold != ""
          )
      )
    );
  }
  return (
    form.question.trim() !== "" &&
    form.questionType !== "" &&
    form.regions.length > 0 &&
    form.shifts.length > 0
  );
};

const isLastFormValid = computed(() => {
  const lastForm = addQuestionForm.value[addQuestionForm.value.length - 1];
  return isFormValid(lastForm);
});

const areAllFormsValid = computed(() => {
  return addQuestionForm.value.every(isFormValid);
});

const addQuestions = () => {
  showError.value = true;
  if (addQuestionForm.value.every(isFormValid)) {
    loadingButton.value = true;
    const payload = {
      questions: addQuestionForm.value.map((form) => {
        const baseQuestion = {
          region: form.regions.map((r) => r.name.toLowerCase()),
          shift: form.shifts.map((s) => s.id),
          activity: form.question,
          type: form.questionType.name.toLowerCase(),
        };

        if (form.questionType.id === 3) {
          return {
            ...baseQuestion,
            thresholds: form.practices.map((practice) => ({
              practiceId: practice.practiceId,
              practiceThresholds: practice.practiceThresholds,
            })),
          };
        } else {
          return baseQuestion;
        }
      }),
    };
    if (isNew.value) {
      const newPayload = { payload, isNew: isNew.value };
      store.dispatch("hqModelDay/addQuestions", newPayload).then(() => {
        Swal.fire({
          title: `Your Activity List Is Successfully Created!`,
          iconHtml: `<img src="${icon}" />`,
          customClass: {
            title: "custom-title",
            popup: "border-radius",
            content: "custom-text",
            icon: "icon-border",
          },
          timer: 2000,
          showConfirmButton: false,
          background: `rgba(225, 249, 240, 1)`,
        });
        router.push({
          name: "hq-monitoringOfPracticeFunctionsModelDayDashboard",
        });
      });
    } else {
      loader.value = true;
      const newPayload = { payload, isNew: isNew.value };
      store.dispatch("hqModelDay/addQuestions", newPayload).then(() => {
        addQuestionForm.value = [
          {
            question: "",
            questionType: "",
            regions: [],
            shifts: [],
            practices: [],
          },
        ];
        addTask.value = false;

        Swal.fire({
          toast: true,
          position: "top-end",
          title: `Activity is added Successfully!`,
          iconHtml: `<img src="${icon}" />`,
          width: "32em",
          customClass: {
            title: "popup-title",
            popup: "popup-border",
            content: "popup-text",
            icon: "popup-icon-border",
          },
          timer: 2000,
          showConfirmButton: false,
          background: `rgba(225, 249, 240, 1)`,
          didClose: () => {
            Swal.fire({
              padding: "6px",
              title: "Activities List Will be Updated Tomorrow!",
              confirmButtonColor: "rgba(0, 164, 153, 1)",
              customClass: {
                title: "custom-delete-title",
                popup: "border-radius",
                content: "custom-text",
                icon: "icon-border",
                cancelButton: "custom-cancel-button",
              },
            });
          },
        });

        loader.value = false;
      });
    }
    loadingButton.value = false;
    showError.value = false;
  } else {
    showError.value = true;
  }
};

const addNewQuestion = () => {
  if (isLastFormValid.value) {
    if (isNew.value) {
      addQuestionForm.value.push({
        question: "",
        questionType: "",
        regions: [],
        shifts: [],
        practices: [],
      });
    } else {
      addQuestionForm.value.push({
        question: "",
        questionType: "",
        regions: [],
        shifts: [],
        practices: [],
      });
      updateRegionValue(
        [
          {
            id: activeRegion.value,
            name: activeRegion.value == 1 ? "Wales" : "England",
          },
        ],
        addQuestionForm.value.length - 1
      );
      updateShiftValue(
        [
          {
            id: activeShift.value,
            name:
              activeShift.value == 1
                ? "Morning 8:00 am"
                : activeShift.value == 2
                ? "Mid Day 11:00 am"
                : "Evening 4:00 pm",
          },
        ],
        addQuestionForm.value.length - 1
      );
    }
    showError.value = false;
  } else {
    showError.value = true;
  }
};

const handleAddNewTask = () => {
  loadingForm.value = true;
  if (isView.value) {
    addQuestionForm.value[0].question = "";
    addQuestionForm.value[0].questionType = "";
    isView.value = false;
  } else {
    if (addQuestionForm.value.length > 1) {
      addQuestionForm.value.pop();
    }
    addTask.value = true;
    updateRegionValue(
      [
        {
          id: activeRegion.value,
          name: activeRegion.value == 1 ? "Wales" : "England",
        },
      ],
      addQuestionForm.value.length - 1
    );
    updateShiftValue(
      [
        {
          id: activeShift.value,
          name:
            activeShift.value == 1
              ? "Morning 8:00 am"
              : activeShift.value == 2
              ? "Mid Day 11:00 am"
              : "Evening 4:00 pm",
        },
      ],
      addQuestionForm.value.length - 1
    );
  }
  loadingForm.value = false;
};

const activity = computed(() => {
  return store.getters["hqModelDay/getActivity"];
});

const practicesList = computed(() => {
  return store.getters["hqModelDay/getPractices"];
});

const handleCancelButton = () => {
  if (isNew.value) {
    addQuestionForm.value.pop();
  } else {
    if (addQuestionForm.value.length > 1) {
      addQuestionForm.value.pop();
      isView.value = false;
    } else addTask.value = false;
  }
};

watch(
  () => activity.value,
  () => {
    addTask.value = true;
    isView.value = true;
    addQuestionForm.value[0].questionType = activity.value.type.includes(
      "numeric"
    )
      ? { id: 3, name: activity.value.type }
      : activity.value.type;
    addQuestionForm.value[0].question = activity.value.activity;

    updateShiftValue(
      [
        {
          id: activity.value.shift.id,
          name:
            activity.value.shift.id === 0
              ? "Morning 8:00 am"
              : activity.value.shift.id === 1
              ? "Mid Day 11:00 am"
              : "Evening 4:00 pm",
        },
      ],
      0
    );
    updateRegionValue(
      [
        {
          id: activity.value.region?.includes("england") ? 0 : 1,
          name:
            activity.value.region?.charAt(0)?.toUpperCase() +
            activity.value.region?.slice(1),
        },
      ],
      0
    );
  },
  { deep: true }
);

const pageChange = (pageNumber) => {
  page.value = pageNumber;
};

watch(
  () => addQuestionForm.value.map((form) => form.questionType),
  () => {
    formValid.value = addQuestionForm.value.every(isFormValid);
  },
  { deep: true }
);

const fetchSingleActivity = (activityId) => {
  loadingForm.value = true;
  store
    .dispatch("hqModelDay/fetchSingleActivity", {
      activityId: activityId,
      shift: activeShift.value,
    })
    .then(() => {
      loadingForm.value = false;
    });
};

watchEffect(async () => {
  let payloadRegion = [];
  const shouldLoadPractices = addQuestionForm.value.some((form) => {
    if (form.questionType.id === 3) {
      payloadRegion = form.regions.map(
        (region) =>
          region?.name?.charAt(0).toUpperCase() + region?.name?.slice(1)
      );
      return true;
    }
    return false;
  });
  if (shouldLoadPractices) {
    const response = await store.dispatch(
      "hqModelDay/loadPractices",
      payloadRegion
    );
    addQuestionForm.value[addQuestionForm.value.length - 1].practices =
      response.map((practice) => {
        return {
          practiceId: practice.id,
          practiceThresholds: [],
        };
      });
    loadingThresholdTable.value = false;
  }
});
const shiftActivities = computed(() => {
  return store.getters["hqModelDay/getActivities"];
});

watchEffect(async () => {
  if (!isNew.value) {
    loader.value = true;
    await store.dispatch("hqModelDay/fetchModelDayActivities", {
      shift: activeShift.value,
      region: activeRegion.value ? "Wales" : "England",
      page: page.value,
    });
    loader.value = false;
  }
});
</script>
<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
  border: 2px solid rgba(223, 223, 223, 0.596);
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
  color: white;
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
