<!-- @format -->

<template>
  <div ref="dropdown" class="mb-4 relative">
    <label class="text-gray-500 text-sm font-light">{{ label }}</label>
    <div
      class="border-2 border-gray-200 rounded-md w-full flex justify-between items-center mt-2"
    >
      <div class="flex justify-between items-center h-12">
        <div
          v-if="!updatedValue.length && isNew"
          class="px-4 text-sm text-gray-300"
        >
          {{ props.placeholder }}
        </div>
        <div v-else class="font-normal ml-2 flex" :key="updatedValue">
          <div
            class="px-2 py-1 mr-1 bg-gray-100 text-gray-700 rounded-md flex justify-between items-center"
            v-for="(item, index) in updatedValue"
            :key="index"
          >
            <span class="text-sm">{{ item.name }}</span>
            <div
              class="w-4 ml-2 cursor-pointer flex"
              v-if="isNew"
              @click="removeChip(index)"
            >
              <img src="../../../assets/images/cancel.png" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="isNew" @click="toggleOptions">
        <transition mode="out-in">
          <div v-if="showOptions" class="material-icons w-8 h-3 cursor-pointer">
            keyboard_arrow_up_outlined
          </div>
          <div v-else class="material-icons w-8 h-3 cursor-pointer">
            keyboard_arrow_down_outlined
          </div>
        </transition>
      </div>
    </div>

    <transition name="slide-fade">
      <ul
        v-if="showOptions"
        class="border-r border-l border-b rounded-b-md absolute left-0 right-0 bg-white z-10 top-18"
      >
        <li
          class="py-2 px-4 border-b text-sm text-gray-700 hover:bg-teal hover:text-white cursor-pointer w-full"
          v-for="option in props.options"
          :key="option.id"
          @click="addItem(option)"
        >
          {{ option.name }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  onMounted,
  onUnmounted,
} from "vue";

import { useRoute } from "vue-router";

const props = defineProps({
  label: {
    type: String,
    default: "Select",
  },
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "Select an option",
  },
  modelValue: {
    type: Array,
    default: () => [],
  },
});

const route = useRoute();
const isNew = ref(parseInt(route.params.isNew));
const updatedValue = ref([]);
const emit = defineEmits(["update-modelValue"]);

const showOptions = ref(false);

watch(
  () => props.modelValue,
  (newValue) => {
    if (!isNew.value) updatedValue.value = newValue;
  },
  { immediate: true }
);

const toggleOptions = () => {
  showOptions.value = !showOptions.value;
};

const addItem = (item) => {
  const itemExists = updatedValue.value.some(
    (existingItem) => existingItem.id === item.id
  );

  if (!itemExists) {
    updatedValue.value.push(item);
    emit("update-modelValue", updatedValue.value);
  }
};

const removeChip = (index) => {
  updatedValue.value.splice(index, 1);
  emit("update-modelValue", updatedValue.value);
};

const dropdown = ref(null);

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

const handleClickOutside = (event) => {
  if (!dropdown.value.contains(event.target)) {
    showOptions.value = false;
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
